import { Children } from 'react';
import { FCWithChildren } from '../../types/FCWithChildren';

const Header: FCWithChildren = (props) => {
  return props.children ? (
    <div data-cy="dashboard-block-header" className="border-primary-2 items-left flex flex-shrink flex-col border-b p-2 font-medium">
      {props.children}
    </div>
  ) : null;
};

const Body: FCWithChildren<{ bodyPadding?: boolean }> = (props) => {
  const { bodyPadding = true, children } = props;
  return (
    <div data-cy="dashboard-block-body" className={`${bodyPadding ? 'px-4 py-2' : ''} flex max-h-full flex-grow flex-col`}>
      {children}
    </div>
  );
};

const Footer: FCWithChildren = (props) => {
  if (!props.children) {
    return null;
  }
  return (
    <div data-cy="dashboard-block-footer" className="border-gray-5 flex flex-shrink items-center border-t-2 px-4 py-2">
      <div className="w-full">{props.children}</div>
    </div>
  );
};

type DashboardProps = {
  className?: string;
  scrollOverflow?: boolean;
};

type DashboardBlockType<p> = FCWithChildren<p> & {
  Header: typeof Header;
  Footer: typeof Footer;
  Body: typeof Body;
};

const DashboardBlock: DashboardBlockType<DashboardProps> = (props) => {
  const { children, className = '', scrollOverflow } = props;
  const childrenArr = Children.toArray(children);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const header = childrenArr.find(({ type }: any) => type === Header);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const footer = childrenArr.find(({ type }: any) => type === Footer);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const body = header || footer ? childrenArr.find(({ type }: any) => type === Body) : children;

  return (
    <div className={`${className} border-gray-5 flex h-full w-full flex-col rounded-xl border-2 bg-white text-left`} data-cy="dashboard-block">
      {header}
      <div
        className={`${scrollOverflow ? 'max-h-full overflow-y-auto' : ''} flex max-h-full flex-grow flex-col`}
        data-cy="dashboard-block-body-wrapper"
      >
        {body}
      </div>
      {footer}
    </div>
  );
};

DashboardBlock.Header = Header;
DashboardBlock.Body = Body;
DashboardBlock.Footer = Footer;

export default DashboardBlock;
